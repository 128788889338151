import { Dispatch, SetStateAction } from 'react';
import key from 'weak-key';
import { isEmpty } from 'lodash';
import { Button } from '@geberit/gdds';

// types
import { FilterCategory, FilterObject } from '../../../scenes/Campus/CampusCatalog';

// components
import { FilterDropdownProvider } from './FilterDropdownContext';
import FilterDropdown from './FilterDropdown';
import { Translation } from 'components/Translation/Translation';
import { Loader } from 'components/Loader/Loader';

// styles
import styles from './filter.module.scss';

type FilterWrapperProps = {
  availableFilters: FilterObject;
  appliedFilters: FilterObject;
  setAppliedFilters: Dispatch<SetStateAction<FilterObject>>;
  resetAppliedFilters: () => void;
};

export function FilterWrapper({
  availableFilters,
  appliedFilters,
  setAppliedFilters,
  resetAppliedFilters,
}: Readonly<FilterWrapperProps>) {
  if (isEmpty(availableFilters)) {
    return (
      <div className="campus-filter">
        <div className={styles.campusFilter}>
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <FilterDropdownProvider>
      <div className="campus-filter">
        <div className={styles.campusFilter}>
          <div className={styles.filterWrapper}>
            {Object.keys(availableFilters)
              .map((filterKey) => availableFilters[filterKey])
              .map((filterCategory: FilterCategory) => (
                <FilterDropdown
                  key={key(filterCategory)}
                  title={filterCategory.translationKey}
                  filterCategory={filterCategory}
                  appliedFilters={appliedFilters[filterCategory.translationKey]}
                  setAppliedFilters={setAppliedFilters}
                  resetAppliedFilters={resetAppliedFilters}
                />
              ))}
            <div className={`${styles.filterItem} ${styles.filterItemReset}`}>
              <Button stylingType="flat" onClick={resetAppliedFilters}>
                <Translation id="campus_resetfilter" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </FilterDropdownProvider>
  );
}

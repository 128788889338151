import { useState, useEffect, useRef, useCallback } from 'react';

// styles
import styles from './filter.module.scss';

// components
import { FilterDropdownViewDashboard } from './FilterDropdownViewDashboard';

// utils
import { useFilterDropdownContext } from './FilterDropdownContext';
import { useTranslationByKey } from 'utils/hooks/use-translations';
import { toSorted } from 'utils/to-sorted';
import { classNameBuilder } from 'utils/classNameBuilder';

interface FilterDropdownProps {
  reset: boolean;
  setReset: (value: boolean) => void;
  title: string;
  options: any[];
  setFilter?: (values: any[]) => void;
  singleValue?: boolean;
  defaultSelectedValues: any[];
}

export function FilterDropdownDashboard({
  reset,
  setReset,
  title,
  options,
  setFilter = () => {},
  singleValue = false,
  defaultSelectedValues = [],
}: Readonly<FilterDropdownProps>) {
  const flyoutRef = useRef<HTMLDivElement>(null);
  const { openedDropdown, setOpenedDropdown } = useFilterDropdownContext();

  const [selectedValues, setSelectedValues] = useState<any[]>(defaultSelectedValues);
  const [lastReport, setLastReport] = useState<any[]>([]);
  const allTranslation = useTranslationByKey('campus_all');

  const isDropdownOpen = openedDropdown === title;

  const toggleDropdown = useCallback(() => {
    if (isDropdownOpen) {
      setOpenedDropdown(null);
    } else {
      setOpenedDropdown(title);
    }
  }, [isDropdownOpen, setOpenedDropdown, title]);

  const sendReports = useCallback(() => {
    toggleDropdown();
    setLastReport(selectedValues);
    setFilter(selectedValues);
    window.location.hash = 'page=1';
  }, [selectedValues, setFilter, toggleDropdown]);

  /**
   * Close Flyout on outer click
   */
  useEffect(() => {
    function handleOutsideClick(e) {
      if (isDropdownOpen && flyoutRef.current && !flyoutRef.current.contains(e.target)) {
        if (
          selectedValues &&
          toSorted(selectedValues).join(',') !== toSorted(lastReport).join(',')
        ) {
          sendReports();
        } else {
          setOpenedDropdown(null);
        }
      }
    }

    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [selectedValues, isDropdownOpen, lastReport, sendReports, setOpenedDropdown]);

  useEffect(() => {
    if (reset) {
      setSelectedValues([]);
      setReset(false);
    }
  }, [reset, setReset]);

  const onChange = (value) => {
    if (value.includes('_all')) {
      setSelectedValues([]);
      return;
    }

    setSelectedValues(!singleValue ? [...value] : [value]);
  };

  const onClick = (item) => {
    if (item.value === '_all') {
      setSelectedValues([]);
    } else {
      const newValues = selectedValues.includes(item.value)
        ? selectedValues.filter((v) => v !== item.value)
        : [...selectedValues, item.value];
      setSelectedValues(newValues);
    }
  };

  return (
    <div
      ref={flyoutRef}
      className={classNameBuilder(
        styles.filterItem,
        selectedValues.length === 0 && styles.inactive,
      )}
    >
      <FilterDropdownViewDashboard
        sendReports={sendReports}
        onChange={onChange}
        onClick={onClick}
        opened={isDropdownOpen}
        toggleDropdown={toggleDropdown}
        selectedValues={selectedValues.length > 0 ? selectedValues : ['_all']}
        title={title}
        options={[{ id: '_all', value: '_all', text: allTranslation }, ...options]}
      />
    </div>
  );
}

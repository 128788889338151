import { useEffect, useState } from 'react';
import key from 'weak-key';
import { useRouter } from 'next/navigation';
import { useSelector } from 'react-redux';
import { Divider } from '@geberit/gdds';

// styles
import styles from './courseList.module.scss';

// components
import Course from './Course';
import NoResults from '../NoResults/NoResults';
import PaginationArrows from 'components/Pagination/PaginationArrows';
import { Loader } from 'components/Loader/Loader';
import { Translation } from 'components/Translation/Translation';

// utils
import { useCourseUserList } from 'utils/hooks/useCampus';
import { scrollToPosition } from 'utils/scrollTo';
import { campusCatalogUrlSelector } from 'utils/selectors/globalsSelectors';
import PerPage from './PerPage';

interface CourseListProps {
  filters: {
    [filtername: string]: string | number | boolean;
  };
  filterReset: (filterResetValue: any) => void;
}

export default function CourseList({ filters = {}, filterReset }: Readonly<CourseListProps>) {
  const [page, setPage] = useState(0);
  const campusCatalogUrl = useSelector(campusCatalogUrlSelector);
  const router = useRouter();

  const [perPage, setPerPage] = useState(6);
  const [lastPerPage, setLastPerPage] = useState(6);
  const [hasInitialCourses, setHasInitialCourses] = useState<boolean | undefined>(undefined);
  const { itemList, itemCount, isLoading } = useCourseUserList({
    offset: page * perPage,
    limit: perPage,
    orderBy: 'startDate',
    orderDir: 'desc',
    filter: filters,
  });

  useEffect(() => {
    if (itemCount > 0 && hasInitialCourses === undefined) {
      setHasInitialCourses(parseInt(itemCount, 10) > 0);
    }
  }, [hasInitialCourses, itemCount]);

  const filterCompare = JSON.stringify(filters);

  useEffect(() => {
    setPage(0);
  }, [filterCompare]);

  useEffect(() => {
    scrollToPosition(0, 0);
  }, [page]);

  useEffect(() => {
    setPage((lastPage) => Math.ceil((lastPage * lastPerPage + 1) / perPage) - 1);
  }, [lastPerPage, perPage]);

  const hasCourses = itemCount > 0;

  if (isLoading && !itemCount) {
    return (
      <div className={styles.loaderPlaceholder}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.courseListBody}>
      <aside className={styles.listHead}>
        <div className={styles.courseCount}>
          <Translation
            id="campus_result_indicator"
            placeholder={{ amount: String(itemCount || 0) }}
          />
        </div>
        <PerPage perPage={perPage} setPerPage={setPerPage} setLastPerPage={setLastPerPage} />
      </aside>
      <section className={styles.courseListBody}>
        {isLoading && (
          <div className={styles.loaderLayer}>
            <Loader />
          </div>
        )}
        {hasCourses && (
          <>
            {itemList.map((item) => (
              <Course key={key(item)} {...item} />
            ))}
          </>
        )}
        {!hasCourses && (
          <NoResults
            textKey={hasInitialCourses ? 'campus_results_noresults' : 'campus_results_nobooked'}
            buttonKey={hasInitialCourses ? 'campus_resetfilter' : 'campus_switch_catalog'}
            buttonHandler={
              hasInitialCourses
                ? filterReset
                : () => {
                    router.push(campusCatalogUrl);
                  }
            }
          />
        )}
      </section>
      {hasCourses && Math.ceil(itemCount / perPage) > 1 && (
        <aside className={styles.courseListFooter}>
          <div className={styles.courseListPagination}>
            <div className={styles.flexRow}>
              <PaginationArrows
                length={Math.ceil(itemCount / perPage)}
                handleClickDots={(nextPage) => {
                  setPage(nextPage);
                }}
                handleClickButtons={(nextPage) => {
                  setPage((currentPage) => currentPage + nextPage);
                }}
                currentPageIndex={page}
              />
            </div>
          </div>
        </aside>
      )}
      <section className={styles.divider}>
        <Divider />
      </section>
    </div>
  );
}

// components
import { CourseTeaser } from './CourseTeaser';

// styles
import styles from './courseTeaser.module.scss';

// types
import { CourseDateList } from '../types';

// utils
import { useDateFormat } from 'utils/hooks/use-dateformat';
import { useCampusEnv, useCampusTypes, useCourseDetails } from 'utils/hooks/useCampus';
import { formatCampusDate } from 'utils/campus-date-format';
import { getMetatag } from '../metatags';

interface CatalogCourseTeaserLoaderProps {
  courseId: number;
  catalogData: any;
  targetGroups: any[];
  isLandingPage?: boolean;
}

export default function CatalogCourseTeaserLoader({
  courseId,
  catalogData,
  targetGroups = [],
  isLandingPage = false,
}: Readonly<CatalogCourseTeaserLoaderProps>) {
  const { CAMPUS_META } = useCampusEnv();
  const { data, isLoading } = useCourseDetails(courseId);
  const [icon] = useCampusTypes(data?.topics);
  const localeDateFormat = useDateFormat();

  const idInCourseTargetGroups = (id) =>
    !!data?.topics?.find((i) => parseInt(i?.id, 10) === parseInt(id, 10));
  const courseTargetGroups = targetGroups?.filter((i) => idInCourseTargetGroups(i?.id));

  if (isLoading || !data) {
    return (
      <CourseTeaser
        className={isLandingPage ? styles.landingpage : styles.catalogpage}
        icon={icon}
        courseId={courseId}
        courseType="..."
        courseHeadline={catalogData.title}
        courseDescription="..."
        targetGroup={courseTargetGroups?.map((i) => i.name).join(', ')}
        showDetailsButton
        isLandingPage={isLandingPage}
        courseDate={[
          formatCampusDate(catalogData?.startDateStr, localeDateFormat),
          formatCampusDate(catalogData?.endDateStr, localeDateFormat),
        ].filter((item) => item !== null)}
      />
    );
  }

  const metaTags = data?.metaTags;
  const dateStart = getMetatag(CAMPUS_META.START_DATE, metaTags, true);
  const dateEnd = getMetatag(CAMPUS_META.END_DATE, metaTags, true);
  const courseDates: CourseDateList = [];
  if (dateStart) {
    courseDates.push(formatCampusDate(dateStart, localeDateFormat));
  }
  if (dateEnd) {
    courseDates.push(formatCampusDate(dateEnd, localeDateFormat));
  }
  const isPopular = data?.topics?.find((el) => el.id === CAMPUS_META.POPULAR);

  return (
    <CourseTeaser
      className={isLandingPage ? 'landingpage' : 'catalogpage'}
      icon={icon}
      courseId={courseId}
      courseType={data.learningForm}
      courseHeadline={getMetatag(CAMPUS_META.NAME, metaTags) ?? ''}
      courseDate={courseDates.filter(Boolean)}
      targetGroup={courseTargetGroups?.map((i) => i.name).join(', ')}
      courseDescription={getMetatag(CAMPUS_META.SUMMARY, metaTags) ?? ''}
      duration={getMetatag(CAMPUS_META.DURATION, metaTags)}
      // location={(type === 'event' && getMetatag(CAMPUS_META.LOCATION, metaTags)) || undefined}
      showDetailsButton
      isPopular={!!isPopular}
      isLandingPage={isLandingPage}
    />
  );
}

'use client';

import Image from 'next/image';
import { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import key from 'weak-key';
import { Divider, Button } from '@geberit/gdds';

// styles
import layoutStyles from './layout.module.scss';
import styles from './campus-landing.module.scss';

// constants
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';

// components
import CampusLayout from './CampusLayout';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { Translation } from 'components/Translation/Translation';
import { ContactModule } from 'components/Campus/Contact/ContactModule';
import CatalogCourseTeaserLoader from 'components/Campus/CourseTeaser/CatalogCourseTeaserLoader';
import { IntroText } from 'components/ContentElementsGdds/IntroText/intro-text';
import { Headline } from 'components/ContentElementsGdds/headline/headline';

// types
import type { ICourse } from './campus-catalog-server';

// utils
import { decodingContent } from 'utils/decodingContent';
import { useCiamLogin } from 'utils/hooks/useCiam';
import { useClassifications, useCampusEnv } from 'utils/hooks/useCampus';
import {
  campusCatalogUrlSelector,
  campusDashboardUrlSelector,
} from 'utils/selectors/globalsSelectors';
import { initSamlWorkflow } from 'components/Session/SessionContext';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { contentExists, useContent } from 'components/Page/page-hooks';

type Benefit = {
  text: string;
  previewId: string;
  type: string;
  headline: string;
};

interface CampusLandingProps {
  page: Readonly<Content>;
  courseListLanding: ICourse[] | never[];
}

export function CampusLanding({ page, courseListLanding = [] }: Readonly<CampusLandingProps>) {
  const pageContent = useContent<{}, { content: string; benefits: Benefit[] }>();
  const { CAMPUS_CLASS_TARGETGROUPS } = useCampusEnv();

  // used for redirecting after login
  const campusCatalogUrl = useSelector(campusCatalogUrlSelector);
  const campusDashboardUrl = useSelector(campusDashboardUrlSelector);

  const translate = useTranslationFunction();
  const language = useCurrentLanguage();
  const gigyaWindow = typeof window !== 'undefined' ? window['gigya'] : null;
  const { login, loggedIn, campusLoggedIn } = useCiamLogin(gigyaWindow);

  // target groups
  const { getClassification } = useClassifications();
  const targetGroups = getClassification(CAMPUS_CLASS_TARGETGROUPS);
  const country = language?.slice(language?.length - 2);

  if (!contentExists(pageContent)) {
    return null;
  }
  const { breadcrumbs } = page;

  const {
    page: { headline, subHeadline, content, benefits },
  } = pageContent;

  const loginTo = (href: string) => (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (loggedIn) {
      // user may be logged to ciam but not to the campus
      if (!campusLoggedIn) {
        initSamlWorkflow(href, language);
      } else {
        Object.assign(document.createElement('a'), {
          target: '_self',
          href,
        }).click();
      }
    } else {
      login(language, `${country}_Campus`);
    }
  };

  return (
    <CampusLayout isLandingPage>
      <div className={styles.landingPage}>
        <Breadcrumb items={breadcrumbs} />
        <IntroText
          title={headline}
          subtitle={subHeadline}
          intro={''}
          text={content}
          border="disable-border"
          emphasize="title"
          containerClassName={layoutStyles.campusIntroTextContainer}
          disableInEdit
          isFlexItem
        />

        {/* custom pictos */}
        <div className={`grid-x grid-margin-x ${styles.pictos}`}>
          {benefits.map((benefit, i) => (
            <div className="large-4 medium-6 small-12 cell" key={key(benefit)}>
              <Image
                src={`/images/campus/benefit_${i + 1}.png`}
                width={60}
                height={60}
                alt={`Benefit ${i + 1}`}
              />
              <div className={styles.benefitContent}>
                <b>{decodingContent(benefit.headline)}</b>
                <InnerHtml as="p" content={benefit.text} />
              </div>
            </div>
          ))}
        </div>

        {/* login button */}
        <div className={styles.buttonContainer}>
          <Button
            stylingType="primary"
            target="_self"
            href="#"
            className={styles.loginButton}
            onClick={loginTo(campusDashboardUrl)}
          >
            <Translation id="campus_login" />
          </Button>
        </div>

        <Divider />

        <Headline
          tag={Formats.h2}
          title={translate('campus_landingpage_courseteaser_headline')}
          isFlexItem
        />
        {courseListLanding?.length > 0 && (
          <>
            {courseListLanding.map((course) => (
              <CatalogCourseTeaserLoader
                key={key(course)}
                courseId={course.componentId}
                catalogData={course}
                targetGroups={targetGroups || []}
                isLandingPage
              />
            ))}
            <div className={layoutStyles.pagination}>
              <Button stylingType="secondary" onClick={loginTo(campusCatalogUrl)}>
                <Translation id="campus_landingpage_courseteaser_viewmore" />
              </Button>
            </div>
          </>
        )}

        <Divider />

        {/* contact module */}
        <ContactModule />
      </div>
    </CampusLayout>
  );
}

'use client';

import { Search } from '@geberit/gdds';
import { useRouter } from 'next/navigation';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import key from 'weak-key';

// styles
import styles from './layout.module.scss';
import courseListStyles from 'components/Campus/Course/courseList.module.scss';

// types
import type { CourseListResponse, ICourse, IFilterItems } from './campus-catalog-server';

// components
import CatalogCourseTeaserLoader from 'components/Campus/CourseTeaser/CatalogCourseTeaserLoader';
import { FilterWrapper } from 'components/Campus/Filter/FilterWrapper';
import NoResults from 'components/Campus/NoResults/NoResults';
import { Loader } from 'components/Loader/Loader';
import PaginationArrows from 'components/Pagination/PaginationArrows';
import { Translation } from 'components/Translation/Translation';
import { IntroText } from 'components/ContentElementsGdds/IntroText/intro-text';
import CampusLayout from './CampusLayout';
import SortPage, { SORT_MODE_OPTIONS } from 'components/Campus/SortPage/SortPage';

// utils
import { isEmpty } from 'utils/is-empty';
import { useCampusToken } from 'utils/hooks/useCiam';
import { campusReferenceUrlSelector } from 'utils/selectors/globalsSelectors';
import { useTranslationFunction } from 'utils/hooks/use-translations';

interface CampusCatalogProps {
  metaData: Content;
  courseListCatalog: CourseListResponse;
  finalFilterItems: IFilterItems;
}

export interface FilterItem {
  id: string;
  text: string;
  value: string;
}

export interface FilterCategory {
  translationKey: string;
  options: FilterItem[];
}
export interface FilterObject {
  campus_targetgroup: FilterCategory | {};
  campus_competences_knowledge: FilterCategory | {};
  campus_coursetype: FilterCategory | {};
}

export function CampusCatalog({
  metaData,
  courseListCatalog,
  finalFilterItems,
}: Readonly<CampusCatalogProps>) {
  const { docs: unsortedCourseList = [], numFound: totalCourses } = courseListCatalog;

  const { targetGroups, learningForms, competenceKnowledge } = finalFilterItems;

  const router = useRouter();

  const { loading: ciamLoading, campusToken: ciamToken } = useCampusToken();
  const [sortMode, setSortMode] = useState('date_asc');
  const availableFilters: FilterObject = useMemo(() => {
    return {
      campus_targetgroup: {
        translationKey: 'campus_targetgroup',
        options: targetGroups.map((item) => ({
          id: item.id,
          text: item.name,
          value: item.name,
        })),
      },
      campus_competences_knowledge: {
        translationKey: 'campus_competences_knowledge',
        options: competenceKnowledge.map((item) => ({
          id: item.id,
          text: item.name,
          value: item.name,
        })),
      },
      campus_coursetype: {
        translationKey: 'campus_coursetype',
        options: learningForms.map((item) => ({
          id: item.id,
          text: item.name,
          value: item.name,
        })),
      },
    };
  }, [targetGroups, competenceKnowledge, learningForms]);

  const [appliedFilters, setAppliedFilters] = useState<FilterObject>({
    campus_targetgroup: {},
    campus_competences_knowledge: {},
    campus_coursetype: {},
  });
  const resetAppliedFilters = () => {
    setAppliedFilters({
      campus_targetgroup: {},
      campus_competences_knowledge: {},
      campus_coursetype: {},
    });
    setSearchQuery('');
    setPage(1);
    window.location.hash = 'page=1';
  };
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState<number>(1);
  const translate = useTranslationFunction();

  const currentCatalogList = useMemo(() => {
    // special sorting logic is necessary, otherwise courses with startDate=0
    // would be displayed at the top when sorting by start date for the next course
    let sortedItemList: ICourse[] = unsortedCourseList;
    switch (sortMode) {
      case 'date_asc':
        sortedItemList = [
          ...unsortedCourseList.filter((item) => item[SORT_MODE_OPTIONS[sortMode].sortProp]),
          ...unsortedCourseList.filter((item) => !item[SORT_MODE_OPTIONS[sortMode].sortProp]),
        ];
        break;
      case 'lastUpdated_desc':
        sortedItemList.sort((a, b) => {
          return (
            new Date(b[SORT_MODE_OPTIONS[sortMode].sortProp]).getTime() -
            new Date(a[SORT_MODE_OPTIONS[sortMode].sortProp]).getTime()
          );
        });
        break;
      case 'freeseats':
        sortedItemList.sort((a, b) => {
          return b[SORT_MODE_OPTIONS[sortMode].sortProp] - a[SORT_MODE_OPTIONS[sortMode].sortProp];
        });
        break;
      case 'name_asc':
      case 'name_desc':
        sortedItemList.sort((a, b) => {
          if (a[SORT_MODE_OPTIONS[sortMode].sortProp] < b[SORT_MODE_OPTIONS[sortMode].sortProp]) {
            return sortMode === 'name_asc' ? -1 : 1;
          }
          if (a[SORT_MODE_OPTIONS[sortMode].sortProp] > b[SORT_MODE_OPTIONS[sortMode].sortProp]) {
            return sortMode === 'name_asc' ? 1 : -1;
          }
          return 0;
        });
        break;
      default:
        break;
    }
    let filteredItemList = sortedItemList;

    Object.keys(appliedFilters).forEach((filterKey) => {
      if (!isEmpty(appliedFilters[filterKey])) {
        filteredItemList = filteredItemList.filter((course) => {
          return course.classificationIds.some((classificationId) => {
            return classificationId.includes(String(appliedFilters[filterKey].id));
          });
        });
      }
    });

    if (searchQuery.length > 0) {
      filteredItemList = filteredItemList.filter((item) => {
        return (
          item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.description.toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
    }

    return filteredItemList;
  }, [unsortedCourseList, sortMode, appliedFilters, searchQuery]);

  // pagination
  useEffect(() => {
    const hashPage = window.location.hash.match(/page=(\d+)/);
    if (Array.isArray(hashPage)) {
      const pageNumber = Number(hashPage[1]);
      if (pageNumber !== page) {
        const checkedPage = pageNumber > 0 ? pageNumber : 1;
        setPage(checkedPage);
      }
    }
  }, [page, appliedFilters]);

  const changePage = (newPage) => {
    window.location.hash = `page=${page + newPage}`;
    setPage(page + newPage);
  };

  const firstCourseRef = useRef<HTMLDivElement | null>(null);

  const firstCourseScroll = () => {
    if (firstCourseRef.current !== null) {
      window.scrollTo(0, firstCourseRef.current.offsetTop - 80);
    }
  };

  useEffect(firstCourseScroll, [page]);

  const landingUrl = useSelector(campusReferenceUrlSelector);

  const handleSearchSubmit = (searchTerm) => {
    setSearchQuery(searchTerm);
    setPage(1);
    window.location.hash = 'page=1';
  };

  const handleSearchChange = (searchTerm) => {
    searchTerm.length <= 0 && setSearchQuery(searchTerm);
  };

  if (ciamLoading) {
    return (
      <main className={styles.campusMain}>
        <div className={styles.loaderWrapper}>
          <Loader />
        </div>
      </main>
    );
  }

  if (!ciamToken) {
    router.replace(landingUrl);
    return null;
  }

  return (
    <CampusLayout metaData={metaData}>
      <IntroText
        title={translate('campus_headline_catalog')}
        intro={translate('campus_text_catalog')}
        text={''}
        border="disable-border"
        emphasize="title"
        containerClassName={styles.campusIntroTextContainer}
        disableInEdit
        isFlexItem
      />

      <div className={styles.campusSearch}>
        <Search
          name="searchcampus"
          placeholder={translate('campus_search')}
          onSubmit={(searchTerm) => handleSearchSubmit(searchTerm)}
          onChange={(searchTerm) => handleSearchChange(searchTerm)}
          val={searchQuery}
        />
      </div>
      <FilterWrapper
        availableFilters={availableFilters}
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
        resetAppliedFilters={resetAppliedFilters}
      />
      <div ref={firstCourseRef}></div>
      {currentCatalogList && currentCatalogList.length > 0 ? (
        <>
          <div>
            <aside className={`${courseListStyles.listHead} ${courseListStyles.listHeadCatalog}`}>
              <div className={courseListStyles.courseCount}>
                <Translation
                  id="campus_result_indicator"
                  placeholder={{ amount: currentCatalogList.length }}
                />
              </div>
              <SortPage sortMode={sortMode} setSortMode={setSortMode} />
            </aside>
          </div>
          {currentCatalogList.slice(page * 4 - 4, page * 4).map((course) => (
            <CatalogCourseTeaserLoader
              key={key(course)}
              courseId={course.componentId}
              catalogData={course}
              targetGroups={targetGroups}
            />
          ))}
          {Math.ceil(currentCatalogList.length / 4) > 1 && (
            <div className={styles.pagination}>
              <PaginationArrows
                length={Math.ceil(currentCatalogList.length / 4)}
                handleClickDots={(nextPage) => {
                  window.location.hash = `page=${nextPage + 1}`;
                  setPage(nextPage + 1);
                }}
                handleClickButtons={changePage}
                currentPageIndex={page - 1}
              />
            </div>
          )}
        </>
      ) : (
        <NoResults
          textKey="campus_results_noresults"
          buttonKey="campus_resetfilter"
          buttonHandler={resetAppliedFilters}
        />
      )}
    </CampusLayout>
  );
}

'use client';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';

// styles
import styles from './layout.module.scss';

// components
import CampusLayout from './CampusLayout';
import CourseList from 'components/Campus/Course/CourseList';
import { FilterWrapperDashboard } from 'components/Campus/Filter/FilterWrapperDashboard';
import { ContactModule } from 'components/Campus/Contact/ContactModule';
import { Loader } from 'components/Loader/Loader';
import { IntroText } from 'components/ContentElementsGdds/IntroText/intro-text';

// utils
import { useFilterDashboard } from 'utils/hooks/useCampus';
import { campusReferenceUrlSelector } from 'utils/selectors/globalsSelectors';
import { useCampusToken } from 'utils/hooks/useCiam';
import { useTranslationFunction } from 'utils/hooks/use-translations';

export function CampusHome() {
  const [filters, setFilters] = useState({});

  const router = useRouter();
  const { loading: ciamLoading, campusToken: ciamToken } = useCampusToken();
  const translate = useTranslationFunction();
  const filterConfig = useFilterDashboard(setFilters);
  const landingUrl = useSelector(campusReferenceUrlSelector);

  useEffect(() => {
    if (!ciamLoading && !ciamToken) {
      router.replace(landingUrl);
    }
  }, [ciamLoading, ciamToken, landingUrl, router]);

  if (ciamLoading) {
    return (
      <CampusLayout>
        <div className={styles.loaderWrapper}>
          <Loader />
        </div>
      </CampusLayout>
    );
  }

  return (
    <CampusLayout>
      <IntroText
        title={translate('campus_headline_dashboard')}
        intro={translate('campus_text_dashboard')}
        subtitle={translate('campus_subline_dashboard')}
        text={''}
        border="disable-border"
        emphasize="title"
        containerClassName={styles.campusIntroTextContainer}
        disableInEdit
        isFlexItem
      />
      <FilterWrapperDashboard filterConfig={filterConfig} />
      <CourseList filters={filters} filterReset={filterConfig.setFilterReset} />
      <ContactModule gdds />
    </CampusLayout>
  );
}

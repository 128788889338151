import { Button, Flyout } from '@geberit/gdds';

// components
import { Translation } from 'components/Translation/Translation';

// styles
import styles from './filter.module.scss';

type FilterDropdownViewProps = {
  title: string;
  options: any[];
  selectedValues: (string | number)[];
  opened: boolean;
  sendReports: () => void;
  onChange: (value: any) => void;
  onClick: (item: any) => void;
  toggleDropdown: () => void;
};

export function FilterDropdownViewDashboard({
  selectedValues,
  opened,
  title,
  options,
  onChange,
  onClick,
  sendReports,
  toggleDropdown,
}: Readonly<FilterDropdownViewProps>) {
  return (
    <>
      <Button
        onClick={(e) => {
          if (e) {
            e.stopPropagation();
          }
          toggleDropdown();
        }}
        iconAlignment="right"
        symbol={opened ? 'chevron_up' : 'chevron_down'}
      >
        <div className={styles.dropdownTitleWrapper}>
          <span className={styles.dropdownTitle}>
            {selectedValues.length === 1 && selectedValues[0] === '_all' ? (
              <Translation id={title} />
            ) : (
              selectedValues
                .map((el) => options?.find((option) => option.value === el)?.text)
                .join(', ')
            )}
          </span>
        </div>
      </Button>
      {opened && (
        <div className={styles.flyout}>
          <Flyout
            outlined
            type="checkbox"
            items={options}
            name={title}
            onClick={onClick}
            onItemsChange={onChange}
            values={selectedValues}
          >
            <div className={styles.buttonWrapper}>
              <Button stylingType="primary" onClick={sendReports} size="small">
                <Translation id="campus_confirm" />
              </Button>
            </div>
          </Flyout>
        </div>
      )}
    </>
  );
}

import { useState, useRef, useCallback, Dispatch, SetStateAction, useEffect } from 'react';

// styles
import styles from './filter.module.scss';

// types
import type {
  FilterCategory,
  FilterItem,
  FilterObject,
} from '../../../scenes/Campus/CampusCatalog';

// components
import { FilterDropdownView } from './FilterDropdownView';

// utils
import { useFilterDropdownContext } from './FilterDropdownContext';
import { useTranslationByKey } from 'utils/hooks/use-translations';
import { classNameBuilder } from 'utils/classNameBuilder';
import { isEmpty } from 'utils/is-empty';

interface FilterDropdownProps {
  title: string;
  filterCategory: FilterCategory;
  appliedFilters: FilterObject;
  setAppliedFilters: Dispatch<SetStateAction<FilterObject>>;
  resetAppliedFilters: () => void;
}

function FilterDropdown({
  title,
  filterCategory,
  appliedFilters,
  setAppliedFilters,
}: Readonly<FilterDropdownProps>) {
  const allTranslation = useTranslationByKey('campus_all');

  // context
  const { openedDropdown, setOpenedDropdown } = useFilterDropdownContext();

  // state
  const [selectedFilterText, setSelectedFilterText] = useState<string[]>([]);
  const [selectedFilterCat, setSelectedFilterCat] = useState<FilterItem | null>(null);

  // refs
  const flyoutRef = useRef<HTMLDivElement>(null);

  const isDropdownOpen = openedDropdown === title;

  // handlers
  const toggleDropdown = useCallback(() => {
    if (isDropdownOpen) {
      setOpenedDropdown(null);
    } else {
      setOpenedDropdown(title);
    }
  }, [isDropdownOpen, setOpenedDropdown, title]);

  const confirmFilters = () => {
    setAppliedFilters((prev) => ({ ...prev, [filterCategory.translationKey]: selectedFilterCat }));
    window.location.hash = 'page=1';
    toggleDropdown();
  };

  const onClick = (clickedOption: FilterItem) => {
    if (clickedOption.value === '_all') {
      setSelectedFilterText([]);
      setSelectedFilterCat(null);
    } else {
      // check if value is already set
      const newValues = selectedFilterText.includes(clickedOption.value)
        ? selectedFilterText.filter((v) => v !== clickedOption.value)
        : [...selectedFilterText, clickedOption.value];

      // set selected filter text
      setSelectedFilterText(newValues);
      // find the option out of available for the value
      const selectedOption = filterCategory.options.find(
        (option) => option.value === clickedOption.value,
      );
      if (selectedOption) {
        setSelectedFilterCat(selectedOption);
      }
    }
  };

  useEffect(() => {
    if (isEmpty(appliedFilters)) {
      setSelectedFilterText([]);
      setSelectedFilterCat(null);
    } else {
      const selectedFilter = appliedFilters[filterCategory.translationKey];
      if (selectedFilter) {
        setSelectedFilterCat(selectedFilter);
        setSelectedFilterText([selectedFilter.value]);
      }
    }
  }, [appliedFilters, filterCategory]);

  return (
    <div
      ref={flyoutRef}
      className={classNameBuilder(
        styles.filterItem,
        selectedFilterText.length === 0 && styles.inactive,
      )}
    >
      <FilterDropdownView
        confirmFilters={confirmFilters}
        onClick={onClick}
        opened={isDropdownOpen}
        toggleDropdown={toggleDropdown}
        selectedValues={selectedFilterText.length > 0 ? selectedFilterText : ['_all']}
        title={title}
        options={[{ id: '_all', value: '_all', text: allTranslation }, ...filterCategory.options]}
      />
    </div>
  );
}

export default FilterDropdown;

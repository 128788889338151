import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';
import { Button, FormField } from '@geberit/gdds';

// styles
import styles from './layout.module.scss';

// components
import CampusLayout from './CampusLayout';
import { Title as PageHeadline } from 'components/ContentElements/Title/Title';
import { TitleFormats } from 'components/ContentElements/Title/title.types';
import { Translation } from 'components/Translation/Translation';

// utils
import { useCourseDetails, useParticipantLogin } from 'utils/hooks/useCampus';
import { useIsTablet } from 'components/App/SizeProvider';
import { campusReferenceUrlSelector } from 'utils/selectors/globalsSelectors';
import { useTranslationFunction } from 'utils/hooks/use-translations';

interface CampusParticipantLoginProps {
  courseId: number;
}

export default function CampusParticipantLogin({
  courseId,
}: Readonly<CampusParticipantLoginProps>) {
  const { data, isLoading } = useCourseDetails(courseId);
  const translate = useTranslationFunction();
  const [accessCode, setAccessCode] = useState('');

  const { isError } = useParticipantLogin(courseId, {
    onSuccess: () => window.location.reload(),
  });

  const routerHistory = useRouter();
  const landingUrl = useSelector(campusReferenceUrlSelector);
  const isTablet = useIsTablet();

  return (
    <CampusLayout>
      <div className={styles.participantLogin}>
        <div className={styles.image}>
          {/* non-ie aspect ratio: <svg viewBox="0 0 1 1"></svg> */}
          <img
            src={`data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 ${
              isTablet ? 16 : 1
            } ${isTablet ? 9 : 1}'%3E%3C/svg%3E`}
            width="100%"
            alt=""
          />
        </div>
        <form action={routerHistory.asPath} method="GET">
          <div>
            {/* headline */}
            <PageHeadline
              Format={TitleFormats.h1}
              title={translate('campus_headline_accesspage')}
              subtitle={translate('campus_subline_accesspage')}
              className={styles.courseHeadline}
              pageHeadline
            />
            {/* welcome text */}
            <div className={styles.welcomeText}>
              <Translation id="campus_text_accesspage" />
            </div>
            {/* input field */}
            <FormField
              name="accesscode"
              values={{ accessCode }}
              onChange={(e) => setAccessCode(e.currentTarget.value)}
              label={translate('campus_form_accesscode')}
              placeholder={translate('campus_form_accesscode')}
              errorMessage={<Translation id="campus_text_accessinvalid" />}
              errors={isError ? { accesscode: 'invalid' } : undefined}
            />
            {/* availability */}
            {Boolean(courseId) && !isLoading && data?.endDate && (
              <div className={styles.accessUntil}>
                <Translation
                  id="campus_text_accessuntil"
                  placeholder={{ expirationdate: data?.endDate }}
                />
              </div>
            )}
            {/* submit */}
            <Button
              stylingType="primary"
              size="small"
              buttonType="submit"
              className={styles.loginButton}
            >
              <Translation id="campus_login" />
            </Button>
            {/* back to campus landing page */}
            <Button
              className={styles.backButton}
              size="small"
              onClick={() => {
                routerHistory.push(landingUrl);
              }}
            >
              <Translation id="campus_back_to_landing_page" />
            </Button>
          </div>
        </form>
      </div>
    </CampusLayout>
  );
}

import React, { useEffect, useState } from 'react';
import { Button } from '@geberit/gdds';

// components
import { Translation } from '../../Translation/Translation';

// styles
import styles from './sortpage.module.scss';

// settings
export const SORT_MODE_OPTIONS = {
  date_asc: {
    translation: 'campus_sort_date_asc',
    sortProp: 'startDate',
    order: 'asc',
  },
  lastUpdated_desc: {
    translation: 'campus_sort_last_updated_desc',
    sortProp: 'lastUpdated',
    order: 'desc',
  },
  freeseats: { translation: 'campus_sort_freeseats', sortProp: 'freePlaces', order: 'desc' },
  name_asc: { translation: 'campus_sort_name_asc', sortProp: 'title', order: 'asc' },
  name_desc: { translation: 'campus_sort_name_desc', sortProp: 'title', order: 'desc' },
};

interface SortPageProps {
  sortMode: 'date_asc' | 'lastUpdated_desc' | 'freeseats' | 'name_asc' | 'name_desc';
  setSortMode: (value: string) => void;
}

export default function SortPage({ sortMode = 'date_asc', setSortMode }: Readonly<SortPageProps>) {
  // mapping the sortMode to the correct translation
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [sortMode]);

  return (
    <div className={`${styles.box} ${open && styles.open}`}>
      <button type="button" onClick={() => setOpen((prev) => !prev)}>
        <Translation id={SORT_MODE_OPTIONS[sortMode]?.translation} />
      </button>
      {open && (
        <ul className={styles.wrapper}>
          {Object.keys(SORT_MODE_OPTIONS).map((optionKey) => (
            <li key={`sortmode_${optionKey}`}>
              <Button
                buttonType="button"
                stylingType={sortMode === optionKey ? 'flatHighlight' : 'flat'}
                onClick={() => setSortMode(optionKey)}
              >
                <Translation id={SORT_MODE_OPTIONS[optionKey].translation} />
              </Button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

import Image from 'next/image';
import { Container } from '@geberit/gdds';

// components
import Footer from 'components/Footer/Footer';
import { SizeProvider, useIsMobile } from 'components/App/SizeProvider';

// styles
import styles from './layout.module.scss';

// constants
import { THEME_NAMES } from 'themes';

// utils
import { useThemeName } from 'utils/hooks/use-theme';

interface CampusLayoutProps extends React.PropsWithChildren {
  isLandingPage?: boolean;
  noErrorBoundary?: boolean;
}

export default function CampusLayout({ isLandingPage, children }: Readonly<CampusLayoutProps>) {
  const themeName = useThemeName() ?? THEME_NAMES.DEFAULT;
  const isMobile = useIsMobile();

  let maxContentWidth = '90rem';
  if (themeName === 'DEFAULT') {
    maxContentWidth = '80rem';
  }

  return (
    <SizeProvider fallbackWidth={1024}>
      {isLandingPage && (
        <div className={styles.campusBannerWrapper}>
          <Image
            src={isMobile ? '/images/campus-login-mobile.jpg' : '/images/campus-login.jpg'}
            width={1920}
            height={800}
            alt="Campus Banner"
          />
        </div>
      )}
      <Container maxContentWidth={maxContentWidth}>
        <main>{children}</main>
      </Container>
      <Footer isCampus />
    </SizeProvider>
  );
}

import { Fragment } from 'react';
import key from 'weak-key';
import { isEmpty } from 'lodash';
import { Button } from '@geberit/gdds';

// components
import { FilterDropdownProvider } from './FilterDropdownContext';
import { FilterDropdownDashboard } from './FilterDropdownDashboard';
import { Translation } from 'components/Translation/Translation';
import { Loader } from 'components/Loader/Loader';

// utils
import styles from './filter.module.scss';

type FilterWrapperProps = {
  filterConfig: {
    filterItems: any;
    filterReset: any;
    setFilterReset: any;
    sortItems: any;
  };
};

export function FilterWrapperDashboard({
  filterConfig: { filterItems, filterReset, setFilterReset, sortItems },
}: Readonly<FilterWrapperProps>) {
  if (isEmpty(filterItems)) {
    return (
      <div className="campus-filter">
        <div className={styles.campusFilter}>
          <Loader />
        </div>
      </div>
    );
  }
  return (
    <FilterDropdownProvider>
      <div className="campus-filter">
        <div className={styles.campusFilter}>
          <div className={styles.filterWrapper}>
            {filterItems
              ?.filter((item) => item?.options?.length > 0)
              .map((item) => (
                <Fragment key={key(item)}>
                  <FilterDropdownDashboard
                    {...item}
                    options={item.options.sort(sortItems)}
                    reset={filterReset}
                    setReset={setFilterReset}
                  />
                </Fragment>
              ))}
            <div className={`${styles.filterItem} ${styles.filterItemReset}`}>
              <Button stylingType="flat" onClick={() => setFilterReset(true)}>
                <Translation id="campus_resetfilter" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </FilterDropdownProvider>
  );
}
